import { useState, useEffect } from 'react';

export default function DevModeToggle() {
  const [isDevMode, setIsDevMode] = useState(false);

  console.log('DevModeToggle', process.env.NODE_ENV);

  useEffect(() => {
    // Update body class when dev mode changes
    if (isDevMode) {
      document.body.classList.add('dev-mode');
    } else {
      document.body.classList.remove('dev-mode');
    }
  }, [isDevMode]);

  return (
    <div
      data-component="dev-mode-toggle"
      style={{
        top: '40dvh',
        padding: '0.75rem',
        right: '1rem'
      }}
      className="fixed z-50 bg-black rounded-md">
      <button
        aria-label="Toggle Dev Mode"
        onClick={() => setIsDevMode(!isDevMode)}
        className="rounded-full bg-white p-2 text-white hover:bg-accent hover:text-black transition-colors">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24" fill="currentColor" className="text-black">
          <path d="M10 2a8 8 0 015.292 13.708l5 5a1 1 0 01-1.414 1.414l-5-5A8 8 0 1110 2zm0 2a6 6 0 100 12 6 6 0 000-12z" />
        </svg>
      </button>
    </div>
  );
}
