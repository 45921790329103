import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import useSpanHeading from '~/hooks/useSpanHeading';

function BlogPreview(props) {
  const { full_slug, name, content, ...other } = props;

  return (
    <Link href={`/${full_slug}`} {...other}>
      <a className="flex flex-col transition-opacity duration-200 ease-in-out hover:opacity-75">
        <div className="bg-accent w-full overflow-hidden">
          <div className="aspect-w-5 aspect-h-3">
            {content?.previewImage?.filename && (
              <Image
                layout="fill"
                objectFit="cover"
                draggable="false"
                src={content?.previewImage?.filename}
                alt={content?.previewImage?.alt || name || ''}
                quality={100}
                className="w-full h-full"
              />
            )}
          </div>
        </div>

        <div className="mt-6 flex-auto flex flex-col">
          <div className="space-y-4">
            {content?.category?.name.length > 0 ? <p className="inline-block text-lg highlight" dangerouslySetInnerHTML={{ __html: content?.category?.name }} /> : null}
            <h2 className="text-2xl font-medium u-h3" dangerouslySetInnerHTML={{ __html: useSpanHeading(name, 'underswipe') }} />
          </div>

          <div className="flex-auto flex items-end">
            <div className="mt-4 text-link">Read More</div>
          </div>
        </div>
      </a>
    </Link>
  );
}

export default BlogPreview;
