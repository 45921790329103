import React from 'react';
import { storyblokEditable } from '@storyblok/react';

export default function TwoColumnTickList({ blok }) {
  const colCount = blok.columns || 2;

  const colMap = {
    1: 'md:grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3'
  };

  const HeadingTag = blok.headingTagType || 'h2';

  return (
    <div className={`grid ${colMap[colCount]} gap-6 gap-x-12`} {...storyblokEditable(blok)}>
      {blok.items.map(item => (
        <div
          key={item.heading}
          {...storyblokEditable(item)}
          className={`flex border-b border-dashed ${colCount != 1 ? 'pb-6' : ''}`}
          style={{ borderColor: 'rgba(255,255,255,0.1)' }}>
          <div className={`mr-6 ${item?.icon?.filename?.length > 0 || !item.content ? 'mt-1' : 'mt-2'}`} style={{ flex: '0 0 20px' }}>
            {item.icon && item.icon.filename ? (
              <img src={item.icon.filename} alt={item.icon.alt} />
            ) : (
              <img src="https://a-us.storyblok.com/f/1001720/21x16/5b1f9b0152/check.svg" alt="Tick" />
            )}
          </div>
          <div className="flex-auto u-p2 space-y-3">
            {item.heading ? <HeadingTag className={item?.headingClassName || `u-h3 text-22px`}>{item.heading}</HeadingTag> : null}
            {item.content ? <div className="text-16px leading-normal">{item.content}</div> : null}
          </div>
        </div>
      ))}
    </div>
  );
}
