import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { AnimatePresence, motion } from 'framer-motion';
import RenderRichText from '~/components/Storyblok/RenderRichText';
import React, { useState } from 'react';

function QuestionItem({ question, answer, textColour, accColour, i, arr, isStoryblok = false, questionTagType }) {
  const [showAnswer, setShowAnswer] = useState(false);

  const QuestionTag = questionTagType || 'h3';

  return (
    <li
      className={`question-item w-full flex flex-col ${textColour} list-none py-8 ${i === 0 && 'pt-0'}  ${i !== arr.length - 1 && 'border-b border-dashed'}`}
      style={{
        borderColor: textColour === 'text-white' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
      }}>
      <div className="flex items-center justify-between flex-auto w-full cursor-pointer select-none" onClick={() => setShowAnswer(!showAnswer)}>
        <QuestionTag className="u-h3">{question}</QuestionTag>
        <div className="w-8 flex-0">
          <div>
            <svg
              className={` ${showAnswer && 'transform rotate-45'}  transition-all duration-100 ease-in-out question-item-icon`}
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.61613 16.5047V9.89669H0.0361328V7.51669H6.61613V0.908691H9.05213V7.51669H15.6321V9.89669H9.05213V16.5047H6.61613Z"
                fill={`${accColour === 'bg-accent' ? '#F3B545' : '#041B29'}`}
              />
            </svg>
          </div>
        </div>
      </div>

      {showAnswer && (
        <div className={`overflow-hidden mt-4`}>
          <AnimatePresence exitBeforeEnter>
            <motion.div key={answer} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="pt-2 md:mr-12 xl:mr-20">
              <div className={`prose prose-fix-bullets w-full ${textColour}  ${textColour === 'text-white' && 'prose-white'} max-w-full`}>
                {isStoryblok ? <RenderRichText content={answer} /> : answer ? documentToReactComponents(answer) : ''}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      )}
    </li>
  );
}

export default QuestionItem;
