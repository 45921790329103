import React from 'react';
import Typed from 'react-typed';

const VerticalWordsAnimation = React.memo(function VerticalWordsAnimation(props) {
  const { words, longestWord } = React.useMemo(() => {
    try {
      const wordsStr = props.heading.match(/data-words="(.*)"/)[1];
      const parsedWords = wordsStr.split('|');
      const longest = parsedWords.reduce((a, b) => (a.length > b.length ? a : b));
      return { words: parsedWords, longestWord: longest };
    } catch (error) {
      return { words: [], longestWord: '' };
    }
  }, [props.heading]);

  if (!words.length) return null;

  return (
    <div {...props} className={`${props.className}`}>
      <div className="w-full" dangerouslySetInnerHTML={{ __html: props.heading }} style={{ maxWidth: '600px' }} />
      <div className="relative">
        <div className="absolute inset-0">
          <Typed strings={words} typeSpeed={60} backSpeed={50} loop className="flex justify-start text-accent" startDelay={100}>
            <h1 className="text-accent" />
          </Typed>
        </div>
        <div className="opacity-0" aria-hidden="true">
          {longestWord}
        </div>
      </div>
    </div>
  );
});

export default VerticalWordsAnimation;
