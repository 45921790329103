import { useEffect } from 'react';
import Script from 'next/script';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import 'react-modal-video/scss/modal-video.scss';
import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import Components from '~/components/Storyblok/Components';
import JsonLd from '~/components/JsonLd';
import '~/styles/base.scss';
import utmTrackingV2 from '~/utils/utm-tracking-v2';
import DevModeToggle from '../components/DevModeToggle';

storyblokInit({
  accessToken: process.env.storyblokApiToken || 'X6Vft8bNh88RuNsBUUvUGwtt',
  apiOptions: {
    region: 'us'
  },
  use: [apiPlugin],
  components: Components
});

export const pageView = url => {
  posthog?.capture('$pageview');
  window?.dataLayer?.push({
    event: 'page_view',
    page_location: url
  });
};

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'development') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: posthog => {
      // if (process.env.NODE_ENV === 'development') posthog.debug();
    }
  });
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView);
    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  useEffect(() => {
    if (typeof window.console !== 'undefined') {
      utmTrackingV2();
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('hp-a-b-test', router.asPath === '/home' ? 'B' : 'A');
  }, []);

  const handleRouteChange = url => {
    if (window.analytics) {
      window.analytics.page();
    }
    if (window.Intercom) {
      window.Intercom('update');
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'rb9h1eet'
      });
    }
  }, []);

  let darkMode = false;
  if (typeof window !== 'undefined') {
    darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  }
  return (
    <PostHogProvider client={posthog}>
      <Head>
        <link rel="apple-touch-icon" sizes="57x57" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="60x60" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-60x60.png`} />
        <link rel="apple-touch-icon" sizes="72x72" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-72x72.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-76x76.png`} />
        <link rel="apple-touch-icon" sizes="114x114" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-114x114.png`} />
        <link rel="apple-touch-icon" sizes="120x120" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-120x120.png`} />
        <link rel="apple-touch-icon" sizes="144x144" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-144x144.png`} />
        <link rel="apple-touch-icon" sizes="152x152" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-152x152.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`/images/meta/${darkMode ? 'light' : 'dark'}/apple-icon-180x180.png`} />
        <link rel="icon" type="image/png" sizes="192x192" href={`/images/meta/${darkMode ? 'light' : 'dark'}/android-icon-192x192.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/images/meta/${darkMode ? 'light' : 'dark'}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="96x96" href={`/images/meta/${darkMode ? 'light' : 'dark'}/favicon-96x96.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/images/meta/${darkMode ? 'light' : 'dark'}/favicon-16x16.png`} />
        <link rel="manifest" href={`/images/meta/${darkMode ? 'light' : 'dark'}/manifest.json`} />
        <meta name="msapplication-TileImage" content={`/images/meta/${darkMode ? 'light' : 'dark'}/ms-icon-144x144.png`} />
        <meta name="msapplication-TileColor" content="#041b29" />
        <meta name="theme-color" content="#041b29" />
        <meta name="facebook-domain-verification" content="w7w48xizlehpppn83tqxnlk3t23ed8" />
      </Head>

      {process.env.NEXT_PUBLIC_ENV === 'production' ? (
        <>
          <Script id="gtm">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-P3439CX');`}</Script>
        </>
      ) : null}

      <Component {...pageProps} />
      <JsonLd {...pageProps} />
      {process.env.NEXT_PUBLIC_ENV === 'production' ? (
        <Script id="intercom-script" strategy="lazyOnload">
          {`

          window.intercomSettings = {
          app_id: "rb9h1eet"
          }

          console.log('intercom script ready');

          function debounce(n, t, u) {
          var e;
          return function() {
              var i = this,
                  o = arguments,
                  a = u && !e;
              clearTimeout(e), e = setTimeout(function() {
                  e = null, u || n.apply(i, o)
              }, t), a && n.apply(i, o)
          }
          };

          window.loadIntercomOnce = debounce(function() {
          console.log('has scroll');
          window.removeEventListener('scroll', loadIntercomOnce);
          (function() {
              var w = window;
              var ic = window.Intercom;
              if (typeof ic === "function") {
                  ic("boot", {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "rb9h1eet"
                  });

                  ic('reattach_activator');
                  ic('update', window.intercomSettings);
              } else {
                  var s = document.createElement('script');
                  s.type = 'text/javascript';
                  s.async = true;
                  s.src = 'https://widget.intercom.io/widget/rb9h1eet';
                  var x = document.getElementsByTagName('script')[0];
                  x.parentNode.insertBefore(s, x);
              }
          })();
          console.log('intercomloaded');
          }, 1000);

          window.addEventListener('scroll', loadIntercomOnce);
          console.log('hello');
              `}
        </Script>
      ) : null}
      <Script strategy="afterInteractive" id="vidyard-script" src="https://play.vidyard.com/embed/v4.js" />
      {/* {process.env.NODE_ENV === 'development' && <DevModeToggle />} */}
      <DevModeToggle />
    </PostHogProvider>
  );
}

export default MyApp;
