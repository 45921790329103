import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import FaqItem from '~/components/Storyblok/elements/FaqItem';

export default function Faqs({ blok }) {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.faqs.map(faq => (
        <FaqItem key={faq._uid} blok={faq} questionTagType={blok.questionTagType} />
      ))}
    </div>
  );
}
